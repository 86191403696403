import BaseServices from "./base_services";
import Axios from "axios";
const RESOURCE_NAME = "/otp";

class OtpServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }

  getOtp() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${this.RESOURCE_NAME}/get-otp`, { headers });
  }

  resendOtp() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${this.RESOURCE_NAME}/resend-otp`, { headers });
  }

  verifyOtp(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${this.RESOURCE_NAME}/verify-otp`, data, { headers });
  }
}

export default new OtpServices(RESOURCE_NAME);
