<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Verify Otp</h3>
  <div class="section">
    <div class="columns is-multiline text-left">
      <div class="column is-12">
        <p>Your IP is not registered to access this content</p>
        <p>Please contact your administrator</p>
      </div>

      <div class="column is-12">
        <button
          class="button is-primary is-fullwidth"
          @click="sendOtpToAdmin"
          :disabled="otpStatus.sendingOtp"
        >
          {{otpStatus.otpSent ? 'Resend Otp' : 'Send Otp to Admin'}}
        </button>
      </div>

      <div class="column is-12">
        <div class="field is-fullwidth">
          <label class="label">Otp</label>
          <div class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="Otp"
              v-model="data.otp"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="field is-fullwidth">
          <button
            class="button is-primary is-fullwidth"
            @click="verifyOtp"
            :disabled="otpStatus.verifyingOtp"
          >
            Submit Otp
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
